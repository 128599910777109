// TODO: Remove this exception and fix the root issue
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useQueryParams, ArrayParam, withDefault } from 'use-query-params';
import cx from 'classnames';
import SortButtonsTopic from '@components/SortButtonsTopic';
import Title from '@components/Title';
import CTABanner from '@components/CTABanner';
import Breadcrumbs from '@components/Navigation/Breadcrumbs';
import '@styles/sort-content.scss';

import CardCollection from '@components/CardCollection';

const LearnView = ({ data }) => {
  const {
    articles, landingPageSections,
  } = data;
  const breadCrumbs = { titleDisplay: 'Learn from Home', parentPage: null };

  // Merge all card types into single array
  const unsorted = articles.edges.concat(
    landingPageSections.edges,
  );

  // Sort cards by date field
  const cards = unsorted.sort((a, b) => (new Date(a.node.date) > new Date(b.node.date) ? -1 : 1));

  const topics = [
    'accessibility',
    'biology',
    'computer science',
    'earth science',
    'engineering',
    'gender',
    'human rights',
    'income equity',
    'math',
    'physics',
    'race',
    'social science',
  ];

  const allTypeCards = [];

  // Populate card arrays sorted by tag
  for (let i = 0; i < cards.length; i += 1) {
    // create empty array for entries without selected topics
    if (!cards[i].node.topics) {
      cards[i].node.topics = [];
    }
    // set all cards to show by default
    const cardNode = cards[i].node;
    allTypeCards.push(cardNode);
  }

  const initialTopics = [];
  // Set initial state based on query string
  const [query, setQuery] = useQueryParams({
    filters: withDefault(ArrayParam, []),
  });
  for (let i = 0; i < query.filters.length; i += 1) {
    initialTopics.push(query.filters[i]);
  }
  const [cardList, setCardList] = useState(allTypeCards);
  const [selectedTopics, setSelectedTopics] = useState(initialTopics);

  // copy featured cards into their own array
  const featuredCards = [];
  for (let i = 0; i < cardList.length; i += 1) {
    if (cardList[i].featured === true) {
      featuredCards.push({ ...cardList[i] });
    }
  }
  for (let i = 0; i < featuredCards.length; i += 1) {
    featuredCards[i].show = true;
  }

  // if no topics are selected, show all cards
  if (!selectedTopics.length) {
    for (let i = 0; i < cardList.length; i += 1) {
      cardList[i].show = !cardList[i].featured;
    }
  } else {
    // show cards with selected topics
    for (let i = 0; i < cardList.length; i += 1) {
      cardList[i].show = !!(
        !cardList[i].featured
        && ((cardList[i].topics[0]
            && selectedTopics.includes(cardList[i].topics[0]))
          || (cardList[i].topics[1]
            && selectedTopics.includes(cardList[i].topics[1]))
          || (cardList[i].topics[2]
            && selectedTopics.includes(cardList[i].topics[2])))
      );
    }
  }

  function sortTopicCards(topic) {
    let tempTopics = selectedTopics;
    // toggle the entries - check if topic is already in the array
    if (!tempTopics.includes(topic)) {
      tempTopics.push(topic);
      setSelectedTopics(tempTopics);
      setQuery({ filters: selectedTopics });
    } else {
      // remove selected topic from array
      tempTopics = selectedTopics.filter((word) => word !== topic);
      setSelectedTopics(tempTopics);
      setQuery({ filters: tempTopics });
    }
    // show cards with selected topics
    for (let i = 0; i < cardList.length; i += 1) {
      if (
        (cardList[i].topics[0] && tempTopics.includes(cardList[i].topics[0]))
        || (cardList[i].topics[1] && tempTopics.includes(cardList[i].topics[1]))
        || (cardList[i].topics[2] && tempTopics.includes(cardList[i].topics[2]))
      ) {
        cardList[i].show = true;
      } else {
        cardList[i].show = false;
      }
    }
    setCardList(cardList);
  }

  const headerText = 'Learn from home';
  const subHeadText = 'A community resource where Science, Equity, and Education come together.';

  return (
    <>
      <Breadcrumbs parentData={breadCrumbs} />
      <Container className={cx('learn-view', 'p-0', 'mb-4', 'mt-0')}>
        <Row>
          <Col lg={10} md={10} sm={10}>
            <Title title={headerText} classes="short-title" titleSize="h1" />
            <p>{subHeadText}</p>
          </Col>
          <SortButtonsTopic
            sortTopicCards={sortTopicCards}
            topics={topics}
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
          />
          <Col lg={12}>
            <CardCollection cards={featuredCards} path="/learn" />
            <CardCollection cards={cardList} path="/learn" />
          </Col>
        </Row>
      </Container>
      <CTABanner type="visit" />
    </>
  );
};

LearnView.propTypes = {
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default LearnView;
