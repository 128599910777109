import React, { useEffect, useRef } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

const SortButtonsTopic = (props) => {
  const {
    sortTopicCards, topics, selectedTopics, setSelectedTopics,
  } = props;

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 200);
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  function setSelectedStyle(topic) {
    document.getElementById(topic).style.backgroundColor = 'rgb(6, 167, 224)';
    document.getElementById(topic).style.border = '2px solid rgb(6, 167, 224)';
    document.getElementById(topic).style.color = 'white';
  }

  function setDeselectedStyle(topic) {
    document.getElementById(topic).style.backgroundColor = 'white';
    document.getElementById(topic).style.color = 'black';
    document.getElementById(topic).style.border = '2px solid black';
  }

  const sort = topics.map((topic) => {
    useEffect(() => {
      if (selectedTopics.includes(topic)) {
        setSelectedStyle(topic);
      }
      if (!selectedTopics.includes(topic)) {
        setDeselectedStyle(topic);
      }
      if (!selectedTopics.length) {
        setSelectedStyle('clear');
      }
      if (selectedTopics.length) {
        setDeselectedStyle('clear');
      }
      if (selectedTopics.length) executeScroll();
    });

    return (
      <button
        key={topic}
        id={topic}
        type="button"
        onClick={() => sortTopicCards(topic)}
        className="btn-sort"
      >
        {topic}
      </button>
    );
  });

  return (
    <Row>
      <div ref={myRef} />
      <Col className="header-col" lg={1}>TOPIC:</Col>
      <Col className="button-col">
        <button
          id="clear"
          type="button"
          onClick={() => setSelectedTopics([])}
          className="btn-sort"
        >
          All
        </button>
        {sort}
      </Col>
    </Row>
  );
};

SortButtonsTopic.propTypes = {
  sortTopicCards: PropTypes.func.isRequired,
  topics: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTopics: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedTopics: PropTypes.func.isRequired,
};

export default SortButtonsTopic;
