import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'gatsby';
import cx from 'classnames';
import { arrangedParentData, adjustStringLength } from '@utils/navigationHelpers';
import { displayNameLimits } from '@data/navigation';
import './index.scss';

const Breadcrumbs = ({ parentData, classNames }) => {
  const breadCrumbData = arrangedParentData(parentData);
  return (
    <Breadcrumb className={cx('breadcrumbs', 'my-2', 'container', classNames !== null && classNames)}>
      {
        breadCrumbData.map((item, index) => {
          if (index + 1 === breadCrumbData.length) {
            return (
              <BreadcrumbItem active key={item.title}>
                {adjustStringLength(item.title, displayNameLimits.breadcrumbsLink)}
              </BreadcrumbItem>
            );
          }

          return (
            <BreadcrumbItem key={item.title}>
              <Link to={item.link} className="internal-link" title={item.title}>
                {adjustStringLength(item.title, displayNameLimits.breadcrumbsLink)}
              </Link>
            </BreadcrumbItem>
          );
        })
      }
    </Breadcrumb>
  );
};

const parentDataType = PropTypes.shape({
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  parentData: PropTypes.objectOf(PropTypes.any),
  titleBreadcrumb: PropTypes.string,
  titleDisplay: PropTypes.string,
});

Breadcrumbs.defaultProps = {
  classNames: null,
};

Breadcrumbs.propTypes = {
  parentData: parentDataType.isRequired,
  classNames: PropTypes.string,
};

export default Breadcrumbs;
