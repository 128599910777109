import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import useAlertBox from '@utils/useAlertBox';
import StandardPageWrapper from '@templates/StandardPageWrapper';
import HeadSEO from '@components/Navigation/HeadSEO';
import LearnView from '@views/LearnView';
import '@styles/learnView.scss';

export const Head = () => (
  <HeadSEO
    pageTitle="Learn from home"
    pageDescription="A community resource where Science, Equity, and Education come together."
  />
);

const Learn = () => {
  const data = useStaticQuery(graphql`
    query {
      articles: allContentfulArticle(sort: { fields: [date], order: DESC }) {
        edges {
          node {
            __typename
            banner {
              title
              gatsbyImageData(layout: FULL_WIDTH)
            }
            category
            date
            titleDisplay
            slug
            topics
            featured
          }
        }
      }

      landingPageSections: allContentfulLandingPageSections(
        filter: { date: { ne: null }, category: { ne: null } }
        sort: { fields: [date], order: DESC }
      ) {
        edges {
          node {
            __typename
            banner {
              title
              gatsbyImageData(layout: FULL_WIDTH)
            }
            category
            date
            titleDisplay
            titleBreadcrumb
            slug
          }
        }
      }
      contentfulHomePage ( titleDisplay: { eq: "Home Page"} ) {
        alertBox {
          raw
          references {
            ...RichTextReferences
          }
        }
      }
    }
  `);

  const { alertBoxData, hasAlert } = useAlertBox(data);

  return (
    <StandardPageWrapper alertBoxData={alertBoxData} hasAlert={hasAlert}>
      <LearnView data={data} hasAlert={hasAlert} />
    </StandardPageWrapper>
  );
};

export default Learn;
