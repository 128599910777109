/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import CardButton from '@components/CardButton';
import ContentCard from '@components/ContentCard';
import cardSwitch from '../../utils/card-type-switch';

const CardCollection = (props) => {
  const { cards, path } = props;
  const showCards = [];
  for (let i = 0; i < cards.length; i += 1) {
    // show all cards if cardCollection is not being sorted
    if (path !== '/learn') {
      cards[i].show = true;
    }
    if (cards[i].show) {
      showCards.push(cards[i]);
    }
  }

  return (
    <Container className="pt-3" data-cy="card-collection">
      <Row>
        {
          showCards.map((card) => {
            if (card.__typename === 'ContentfulContentCard') {
              return (
                <Col key={card.titleDisplay} md={4} lg={4}>
                  <ContentCard
                    title={card.titleDisplay}
                    body={card.body}
                    button={card.button}
                    imgSrc={card.cardImage}
                    border={card.border}
                  />
                </Col>
              );
            }

            if (card.banner !== null) {
              return (
                <Col key={card.titleDisplay} md={4}>
                  <CardButton
                    imgSrc={card.banner}
                    link={cardSwitch(card)[0]}
                    title={card.titleDisplay || card.titleBreadcrumb}
                    titleSize="h3"
                    tag={cardSwitch(card)[1]}
                    topics={card.topics}
                    altText={card.banner.description}
                    featured={card.featured}
                  />
                </Col>
              );
            }

            return null;
          })
        }
      </Row>
    </Container>
  );
};

CardCollection.propTypes = {
  // TODO: Fix this eslint warning and remove whitelisting
  // eslint-disable-next-line react/forbid-prop-types
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  path: PropTypes.string.isRequired,
};

export default CardCollection;
